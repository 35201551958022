import React from 'react';

import Order from './Order';
import classes from './OrdersList.module.css';

const OrderList = (props) => {
  return (
    <ul className={classes['orders-list']}>
      {props.orders.map((order) => (
        <Order curorderid={props.curorderid}
          key={order.id}
          number={order.number}
        />
      ))}
    </ul>
  );
};

export default OrderList;
