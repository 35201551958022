import React, { useState, useEffect, useCallback } from 'react';

import OrdersList from './components/OrdersList';
import './App.css';

function App() {
  const [ordersPrep, setOrdersPrep] = useState([]);
  const [ordersReady, setOrdersReady] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const querystr = window.atob(window.location.search.substring(1)).split("|");
  const orderid = querystr[0]
  const custname = querystr[1]
  let custnamecapitalized = ""
  if (custname)
    custnamecapitalized += custname.charAt(0).toUpperCase() + custname.slice(1) + ". "

  const loadPrepOrders = async() => {
    const responsePrep = await fetch('https://orbmobile-e2efe-default-rtdb.firebaseio.com/orders-prep.json');
    if (!responsePrep.ok) {
      throw new Error('Something went wrong!');
    }

    const dataPrep = await responsePrep.json();
    const loadedOrdersPrep = [];
    for (const key in dataPrep) {
      loadedOrdersPrep.push({
        id: key,
        number: dataPrep[key].number
      });
    }
    setOrdersPrep(loadedOrdersPrep.reverse());
  }
  const loadReadyOrders = async() => {
    const responseReady = await fetch('https://orbmobile-e2efe-default-rtdb.firebaseio.com/orders-ready.json');
    if (!responseReady.ok) {
      throw new Error('Something went wrong!');
    }

    const dataReady = await responseReady.json();
    const loadedOrdersReady = [];
    for (const key in dataReady) {
      loadedOrdersReady.push({
        id: key,
        number: dataReady[key].number
      });
    }
    setOrdersReady(loadedOrdersReady.reverse());
  }

  const fetchOrdersHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      loadPrepOrders()
      loadReadyOrders()

      setInterval(async () => {
        loadPrepOrders()
        loadReadyOrders()
      }, 5000);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchOrdersHandler();
  }, [fetchOrdersHandler]);

  let contentPrep = <p>Found no orders.</p>;
  if (ordersPrep.length > 0) {
    contentPrep = <OrdersList curorderid={orderid} orders={ordersPrep} />;
  }
  if (error) {
    contentPrep = <p>{error}</p>;
  }
  if (isLoading) {
    contentPrep = <p>Loading...</p>;
  }

  let contentReady = <p>Found no orders.</p>;
  if (ordersReady.length > 0) {
    contentReady = <OrdersList curorderid={orderid} orders={ordersReady} />;
  }
  if (error) {
    contentReady = <p>{error}</p>;
  }
  if (isLoading) {
    contentReady = <p>Loading...</p>;
  }

  return (
    <React.Fragment>
      <div align="center" style={{padding: 10 +'px'}}>{custnamecapitalized}Your order is being prepared...</div>
      <table width="100%">
        <tr valign="top">
          <td className='coltitle' align='center'>Preparing</td>
          <td className='coltitle' align='center'>Served</td>
        </tr>
        <tr valign="top">
            <td width="50%"><section>{contentPrep}</section></td>
            <td width="50%"><section>{contentReady}</section></td>
        </tr>
      </table>
    </React.Fragment>
  );
}

export default App;
