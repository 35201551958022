import React from 'react';

import classes from './Order.module.css';

const Order = (props) => {
  return (
      <li className={props.curorderid === props.number ? classes.currentOrder : classes.order}>
        <h2>{props.number}</h2>
      </li>
  );
};

export default Order;
